<template>
  <v-list>
    <v-list-group
      v-for="(item, index) of adsAccounts"
      :key="index"
      class="pa-0 mx-0 my-3"
    >
      <template v-slot:activator>
        <v-list-item-title class="item-title">
          <p class="pb-0 mb-0">{{ item.name }}</p>
          <small> {{ numberWithSpaces(item.resourceName) }}
            <span v-if="item.linked" style="color: #828282 !important">{{
                $t("my_accounts_page.linked")
              }}</span>
            <span v-else style="color: #828282 !important">
                {{ $t("my_accounts_page.not_linked") }}</span
            > </small>
        </v-list-item-title>
        <br />
      </template>
      <v-list-item class="d-flex align-center item py-0 my-0">
        <span class="name">Number</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="isExpanded=!isExpanded"
        >
          <img :src="plusIcon" alt="" v-if="!isExpanded" />
          <img :src="minusIcon" alt="" v-else class="mt-2" />
        </v-btn>
        <span
          class="font-family-raleway-medium color-gray"
          style="font-size: 16px; color: #000 !important"
        >{{ numberWithSpaces(item.resourceName) }}
            </span>
      </v-list-item>
      <v-list-item class="d-flex align-center item py-0 my-0">
        <span class="name">Status</span>
        <v-spacer></v-spacer>
        <span>
           <v-btn
             v-if="item.linkedItem"
             class="not-linked-style font-family-raleway-medium"
             style="cursor: default"
           >
              <img :src="linkIcon" alt="" />
              <span v-if="item.linked">{{
                  $t("my_accounts_page.linked")
                }}</span>
              <span v-else style="color: #828282 !important">{{
                  $t("my_accounts_page.not_linked")
                }}</span>
            </v-btn>
            <v-btn
              class="not-linked-style font-family-raleway-medium mx-2"
              style="cursor: default"
              v-if="item.manager"
            >
              <span style="color: #0057ff">MCC</span>
            </v-btn>
        </span>
      </v-list-item>
      <v-list-item class="d-flex align-center item py-0 my-0">
        <span class="name">Action</span>
        <v-spacer></v-spacer>
        <span>
          <v-btn
            v-if="item.linkedItem && !item.linked && !item.manager"
            color="primary"
            class="not-linked-style font-family-raleway-medium"
            style="cursor: pointer; background-color: #0057ff !important"
            @click="$emit('showModal', item.resourceName)"
          >
              <span style="color: #fff">{{ $t("my_accounts_page.link") }}</span>
            </v-btn>
        </span>
      </v-list-item>
      <v-divider class="mx-1"></v-divider>
    </v-list-group>

  </v-list>

</template>
<script>
import linkIcon from "../../assets/icon/link.svg";
import plusIcon from "../../assets/icon/bluePlusIcon.svg";
import minusIcon from "../../assets/icon/blueMinusicon.svg";

export default {
  name: "MyAccountsMobileTable",
  props: {
    adsAccounts: Array,
  },
  data: () => ({
    linkIcon,
    plusIcon,
    minusIcon,
    isExpanded : false,
  }),
  methods: {
    numberWithSpaces(val) {
      let phone = val.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3");
      return phone.replaceAll(" ", "-");
    },
  }
};
</script>
<style lang="scss" scoped>
.chip {
  height: 32px;
  border-radius: 10px;
  padding: 0 15px !important;
  text-transform: uppercase;
}

.item {
  .name {
    color: #828282;
  }
}

.item-title {
  p {
    color: #0057ff !important;
    font-size: 18px;
  }

  small {
    font-size: 15px;
    color: #828282;
  }
}
</style>
